@font-face {
  font-family: "Matter Light";
  src: local("Matter-Light"),
    url("./fonts/Matter-Light.ttf") format("truetype");
}
@font-face {
  font-family: "Matter Light Italic";
  src: local("Matter-LightItalic"),
    url("./fonts/Matter-LightItalic.ttf") format("truetype");
}
@font-face {
  font-family: "Matter Regular";
  src: local("Matter-Regular"),
    url("./fonts/Matter-Regular.ttf") format("truetype");
}
@font-face {
  font-family: "Matter Regular Italic";
  src: local("Matter-RegularItalic"),
    url("./fonts/Matter-RegularItalic.ttf") format("truetype");
}
@font-face {
  font-family: "Matter Medium";
  src: local("Matter-Medium"),
    url("./fonts/Matter-Medium.ttf") format("truetype");
}
@font-face {
  font-family: "Matter Medium Italic";
  src: local("Matter-MediumItalic"),
    url("./fonts/Matter-MediumItalic.ttf") format("truetype");
}
@font-face {
  font-family: "Matter SemiBold";
  src: local("Matter-SemiBold"),
    url("./fonts/Matter-SemiBold.ttf") format("truetype");
}
@font-face {
  font-family: "Matter SemiBoldItalic";
  src: local("Matter-SemiBoldItalic"),
    url("./fonts/Matter-SemiBoldItalic.ttf") format("truetype");
}
@font-face {
  font-family: "Matter Bold";
  src: local("Matter-Bold"),
    url("./fonts/Matter-Bold.ttf") format("truetype");
}
@font-face {
  font-family: "Matter BoldItalic";
  src: local("Matter-BoldItalic"),
    url("./fonts/Matter-BoldItalic.ttf") format("truetype");
}
@font-face {
  font-family: "Matter Heavy";
  src: local("Matter-Heavy"),
    url("./fonts/Matter-HeavyItalic.ttf") format("truetype");
}

@font-face {
  font-family: "Newsreader Regular";
  src: local("Newsreader-Regular"),
    url("./fonts/Newsreader-VariableFont_opsz,wght.ttf") format("truetype");
}
@font-face {
  font-family: "Newsreader Italic";
  src: local("Newsreader-Italic"),
    url("./fonts/Newsreader-Italic-VariableFont_opsz,wght.ttf") format("truetype");
}
@font-face {
  font-family: "Newsreader SemiBold";
  src: local("Newsreader-SemiBold"),
    url("./fonts/Newsreader-VariableFont_opsz,wght.ttf") format("truetype");
  font-weight: 600;
}
@font-face {
  font-family: "Newsreader SemiBoldItalic";
  src: local("Newsreader-SemiBoldItalic"),
    url("./fonts/Newsreader-Italic-VariableFont_opsz,wght.ttf") format("truetype");
  font-weight: 600;
}
@font-face {
  font-family: "Newsreader Bold";
  src: local("Newsreader-Bold"),
    url("./fonts/Newsreader-VariableFont_opsz,wght.ttf") format("truetype");
  font-weight: 700;
}
@font-face {
  font-family: "Newsreader BoldItalic";
  src: local("Newsreader-BoldItalic"),
    url("./fonts/Newsreader-Italic-VariableFont_opsz,wght.ttf") format("truetype");
  font-weight: 700;
}

html,
body {
  scroll-behavior: smooth;
  padding: 0;
  margin: 0;
  background-color: #f5f5ed;
  height: 100%;
  font-family: 'Matter';
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

#root {
  display: flex;
  height: 100%;
}

#header {
  width: 100vw;
  z-index: 1000;
  position: fixed;
  top: 0;
}
#homepage {
  width: 50%;
  padding-top: 64px;
}

@media (max-width: 700px) {
  #homepage {
    width: 80%;
  }
}

#searchResults {
  width: 50%;
  margin: 0 auto;
}
@media (max-width: 992px) {
  #searchResults{
    width: 100%;
  }
}

